import { LOCAL_STORAGE_COUNTRY, LOCAL_STORAGE_VENDOR, LOCAL_STORAGE_VENDOR_NAME } from "config/constants";
import { IVendorFull } from "domains/Vendor";
import UserStore from "stores/User/UserStore";
import { APP_ID, APP_NAME, HierarchyGroupsATP, HierarchyGroupsLimits, LIMITS_MODULE_TYPE, NO_HIERARCHY_GROUP_FOUND, PageRouteData, PagesIDs, PathnamePageId } from "../constants";

interface ITrackingDefaultData {
    is_core_event: boolean,
    vendor_country: string | null,
    vendor_display_name: string | null,
    vendor_id: string | null,
    user_email: string | null,
    user_country: string | null,
    user_permission_group: string,
    app_display_name: string,
    app_id: string,
    manufacturer_country: null,
    manufacturer_display_name: null,
    manufacturer_id: null,
    manufacturer_service_model: null,
    manufacturer_tier_name: null,
    vendor_service_model: string | null,
    vendor_tier_name: string | null,
    view_level: string,
    page_label: string,
    page_name: string,
    page_url: string,
    user_id: string | null,
    occurrence_id: string | null,
    preferred_language: string | null,
    user_affiliation: string | null
}

interface IStorageVendor {
    [index: string]: string;
}

export const getHighestAccessGroup = (groups: string[] = [], moduleType: string): string => {

    let groupModule = moduleType === LIMITS_MODULE_TYPE ? HierarchyGroupsLimits : HierarchyGroupsATP;

    const highestPriorityGroup = groups.reduce((highestPriority, currentItem) => {

        const currentPriority = groupModule.findIndex(
            (orderedItem) => orderedItem.toLowerCase() === currentItem.toLowerCase()
        );

        if (currentPriority === -1) {
            return highestPriority;
        }

        if (!highestPriority) {
            return currentItem;
        }

        const highestPriorityValue = groupModule.findIndex(
            (orderedItem) => orderedItem.toLowerCase() === highestPriority.toLowerCase()
        );
        return currentPriority < highestPriorityValue ? currentItem : highestPriority;
    }, undefined as string | undefined);

    return highestPriorityGroup || NO_HIERARCHY_GROUP_FOUND;

}

export const getVendorAdditionalData = (vendorList: IVendorFull[] | null = [], vendorId: string) => {

    const selectedVendor = vendorList?.find((vendor => vendor.id === vendorId));

    return {
        vendor_service_model: selectedVendor ? selectedVendor?.serviceModel : null,
        vendor_tier_name: selectedVendor ? selectedVendor?.tier.name : null,
    }

}

export const getTrackingDefaultData = (pageID: string = PagesIDs.LIMITS_UPLOAD): ITrackingDefaultData => {

    const pageData = PageRouteData[pageID];

    const UserData = UserStore.getState();

    const userPermissionGroups = UserData.groups;

    const countrySelected = (localStorage.getItem(LOCAL_STORAGE_COUNTRY) as string);

    const vendorStorage = (JSON.parse(localStorage.getItem(LOCAL_STORAGE_VENDOR) || '{}') as unknown as IStorageVendor);

    const vendorName = (localStorage.getItem(LOCAL_STORAGE_VENDOR_NAME) as string);

    const userEmail = UserData.data.email;
    const userCountry = UserData.data.country;
    const userId = UserData.data.id as string | null;
    const userPreferredLanguage = UserData.data.preferred_language as string | null;
    const userAffiliation = UserData.data.user_affiliation as string | null;

    const vendorAdditionalData = getVendorAdditionalData(UserData.listVendors, vendorStorage[countrySelected]);

    const staticInfo = {
        app_display_name: APP_NAME,
        app_id: APP_ID,
        is_core_event: false,
        manufacturer_country: null,
        manufacturer_display_name: null,
        manufacturer_id: null,
        manufacturer_service_model: null,
        manufacturer_tier_name: null,
        view_level: 'SINGLE_VENDOR',
        occurrence_id: null
    }

    const result = {
        vendor_country: countrySelected || null,
        vendor_display_name: !vendorStorage[countrySelected] ? null : vendorName,
        vendor_id: vendorStorage[countrySelected] || null,
        user_email: userEmail || null,
        user_country: userCountry || null,
        user_permission_group: getHighestAccessGroup(userPermissionGroups, pageData.type),
        page_label: pageData.page_label,
        page_name: pageData.page_name,
        page_url: document.location.href.toString(),
        user_id: userId,
        preferred_language: userPreferredLanguage,
        user_affiliation: userAffiliation || 'No affiliation',
        ...vendorAdditionalData,
        ...staticInfo
    }

    return result;

}

export const validateEventRequiredData = (eventData: ITrackingDefaultData): boolean => {
    const requiredProperties: (keyof ITrackingDefaultData)[] = [
        'vendor_country',
        'user_email',
        'user_country',
        'user_permission_group',
        'user_id'
    ];

    const missingProps = requiredProperties.filter((prop) => {
        const value = eventData[prop];
        return value === undefined || value === null;
    });

    return missingProps.length === 0;
};

export const GetPageIdByLocation = () => {
    return PathnamePageId[document.location.pathname];
}